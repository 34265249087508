<template>
  <v-container style="min-height: 100vh;">

    <v-card-text class="page-title d-block d-lg-none pt-2 pb-0 pr-0">{{ $route.meta.name }}</v-card-text>
    <v-breadcrumbs class="pb-0 mb-0 pt-3 mr-0 pr-0" :items="items"></v-breadcrumbs>

    <v-row>

      <v-col cols="12" md="8">
        <v-card class="mt-3">
          <v-card-title>
            {{ $t('$vuetify.userWallet.title') }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-list>
              <v-list-item v-for="(walletItem,index) in profile.monetary_wallet" :key="index">

                {{ $t('$vuetify.wallet.total[0]') }}
                <v-chip class="mx-1"
                        width="100"
                        :class="{'white--text red' : walletItem.balance <0,'blue white--text':walletItem.balance>0}">
                  <template v-if="$vuetify.rtl">
                    {{ walletItem.balance.toLocaleString() }} {{ $t(resolveCurrency(index)) }}
                  </template>
                  <template v-else>
                    {{ $t(resolveCurrency(index)) }}{{ walletItem.balance.toLocaleString() }}
                  </template>

                </v-chip>
                {{ $t('$vuetify.wallet.total[1]') }}


              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-text>
            <v-data-table
                hide-default-footer
                :headers="headers"
                :items="records"
            >
              <template v-slot:item.amount="{item}">
                {{ Number(item.amount).toLocaleString() }}
              </template>
              <template v-slot:item.type="{item}">
                {{ resolveWalletRecordType(item.type) }}
              </template>
              <template v-slot:item.currency="{item}">
                {{$t(resolveCurrency(item.currency))}}
              </template>
            </v-data-table>
          </v-card-text>
          <v-pagination
              :length="total"
              v-model="page"
          >
          </v-pagination>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <div class="sticky">
          <EnterGiftCodeComponent @done="getWalletRecords">
          </EnterGiftCodeComponent>

        </div>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import {resolveCurrency} from "../../utilities";
import {mapGetters} from 'vuex';
import EnterGiftCodeComponent from '@/components/EnterGiftCodeComponent'

export default {
  computed: {
    ...mapGetters({
      profile: 'getUserInfo'
    })
  },
  components: {
    EnterGiftCodeComponent
  },
  created() {

    // let client = this.$store.getters.getUserInfo;
    // this.wallet = this.$store.getters.getUserInfo.monetary_wallet;
    this.getWalletRecords();
  },
  methods: {
    getWalletRecords() {
      this.$store.dispatch('getWalletRecords', {page: this.page}).then((resp) => {
        console.log(resp);
        this.records = resp.data.records;
        this.total = resp.data.pagination.total_pages;
      })
    },
    resolveWalletRecordType(type) {
      return type == 'charge' ? this.$t('$vuetify.userWallet.status[0]') : this.$t('$vuetify.userWallet.status[1]');
    },
    resolveCurrency(input) {
      return resolveCurrency(input);
      // return input == 'dollar' ? 'دلار' : 'تومان';
    }
  },
  data() {
    return {
      records: [],
      headers: [
        {text: this.$t('$vuetify.userWallet.headersTable[0]'), value: 'type', align: 'center'},
        {text: this.$t('$vuetify.userWallet.headersTable[1]'), value: 'amount', align: 'center'},
        {text: this.$t('$vuetify.userWallet.headersTable[2]'), value: 'currency', align: 'center'},
        {text: this.$t('$vuetify.userWallet.headersTable[3]'), value: 'date', align: 'center'},
        {text: this.$t('$vuetify.userWallet.headersTable[4]'), value: 'time', align: 'center'},
        // {text: 'توضیحات', value: 'description', align: 'center'},
      ],
      items: [
        {
          text: this.$t('$vuetify.dashboard'),
          disabled: false,
          to: `/${this.$vuetify.rtl ? "fa" : "en"}/panel/dashboard`,
        },
        {
          text: this.$t('$vuetify.ClientNavItem.wallet'),
          disabled: true,
          to: `/${this.$vuetify.rtl ? "fa" : "en"}/panel/sessions`,
        }
      ],
      page: 1,
      total: 1,
      // wallet: null
    }
  },
  filters: {},
  watch: {
    page() {
      this.getWalletRecords();
    }
  },


}
</script>

<style>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
}
</style>